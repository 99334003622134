import React, {useContext, useEffect} from 'react';
import BandCard from './BandCard';
import './BandsContainer.css';
import {AuthContext} from "./AuthContext";

const BandsContainer = ({ bands,followedArtistIds,onFollowChange , text,title_detail }) => {
    // 使用slice方法获取前9个乐队
    const bandsToShow = bands.slice(0, 9);
    const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext); // 使用Context
    useEffect(() => {
        // 检查 localStorage 中是否有 token
        const token = localStorage.getItem('token');
        setIsLoggedIn(!!token); // 使用Context的方法更新登录状态
    }, [setIsLoggedIn]);


    // 新添加的处理函数
    const handleFollowChange = (artistId, shouldFollow) => {
        if (!isLoggedIn) {
            alert('请先登录');
            return;
        }
        onFollowChange(artistId, shouldFollow);
    };

    return (
        <div className="bands-container">
            <div className="text-container">
                <h2 className="container-text-tittle-detail">{text}</h2>
                <h2 className="tittle-detail">{title_detail}</h2>
            </div>
            <div className="band-cards-wrapper">
                {bandsToShow.map((band, index) => (
                    <BandCard
                        key={index}
                        band={band}
                        isFollowed={followedArtistIds.includes(band.artistId)}
                        onFollowChange={handleFollowChange} // 使用新的处理函数
                    />
                ))}
            </div>
        </div>
    );
};

export default BandsContainer;
