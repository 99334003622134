import React, { useState, useEffect } from 'react';
import './BandInfo.css'

const BandDetailInfo = ({ bandInfo }) => {
    const { image_url: imgUrl, name, genre, artistId } = bandInfo;
    const [isFollowed, setIsFollowed] = useState(false);
    const userUrl = process.env.REACT_APP_USER_URL;

    // 检查是否已关注
    useEffect(() => {
        fetch(`${userUrl}/user/isFollowed?artistId=${artistId}`, {
            method: 'GET',
            headers: {
                // 添加认证头信息（如果需要）
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            }
        })
            .then(response => response.json())
            .then(data => setIsFollowed(data)) // 假设后端直接返回布尔值
            .catch(error => console.error('Error:', error));
    }, [artistId, userUrl]);

    // 处理关注/取消关注
    const handleFollowClick = () => {
        const newFollowStatus = !isFollowed;
        const followEndpoint = newFollowStatus ? '/followBand' : '/unfollowBand';

        fetch(`${userUrl}/user${followEndpoint}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                // 添加认证头信息（如果需要）
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({ artistId })
        })
            .then(response => {
                if (response.ok) {
                    setIsFollowed(newFollowStatus);
                } else {
                    throw new Error('Failed to update follow status');
                }
            })
            .catch(error => console.error('Error:', error));
    };


    return (
        <div className="band-info-container-bandinfo">
            <img className="blurred-poster-bandinfo" src={imgUrl} alt="Blurred Band Poster"/>
            <div className="poster-overlay-bandinfo"></div>

            <div className="clear-poster-container-bandinfo">
                <img className="clear-poster-bandinfo" src={imgUrl} alt="Concert Poster"/>
            </div>

            <div className="band-details-container-bandinfo">
            <div className="band-name-container-bandinfo">
                    <h2>{name}</h2>
                </div>
                <div className="band-genre-container-bandinfo">
                    {/*<button
                        className="follow-button"
                        onClick={handleFollowClick}
                        style={{backgroundColor: isFollowed ? '#AAA' : '#FF6666', color: 'white'}}
                    >
                        {isFollowed ? '已关注' : '关注'}
                    </button>*/}
                    <h2>{genre}</h2>
                </div>
            </div>

        </div>
    );
};

export default BandDetailInfo;


