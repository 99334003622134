import React from 'react';
import { Box, Flex, pastheading, Text, Image, VStack, Icon } from '@chakra-ui/react';
import {useNavigate} from 'react-router-dom';
import { FiMapPin, FiCalendar } from 'react-icons/fi'; // 导入所需图标
import './PastConcertDetailsForSearch.css';


function PastConcertDetailsForSearch({ concert }) {
    const navigate = useNavigate();

    if (!concert) {
        return <Box>演出信息不可用。</Box>;
    }
    const formatDate = (datetime) => {
        const date = new Date(datetime);
        return date.toLocaleDateString(); // 获取日期部分并格式化
    };


    return (
        <Box className="concert-details-box" >
            <Flex direction={{ base: 'column', md: 'row' }} className="flex-direction">
                <Box className="pastpastimage-box">
                    <Image
                        src={concert['imgUrl']}
                        alt={concert.title}
                        className="concert-image"
                    />
                </Box>
                <VStack align='start' flex={1}>
                    <pastheading as='h2' size='md' mb={5} className="pastheading">
                        {concert.title}
                    </pastheading>
                    <Text className="artistName" fontSize='lg'>{concert.artist.join(', ')}</Text>
                    <Text fontSize='lg' mt={4}>{concert.description}</Text>
                    <Flex className="bottom-info">
                        <Box className="info-item">
                            <Icon as={FiCalendar} className="icon" color="#0099CC"/>
                            <Text fontSize='lg'>{formatDate(concert["start_datetime"])}</Text>
                        </Box>
                        <Box className="info-item">
                            <Icon as={FiMapPin} className="icon" color="#0099CC"/>
                            <Text fontSize='lg'>{concert["venue"]}</Text>
                        </Box>
                    </Flex>
                </VStack>
            </Flex>
        </Box>
    );
}

export default PastConcertDetailsForSearch;