import React, { useState } from 'react';
import NoConcerts from './NoConcerts'; // 确保导入 NoConcerts 组件
import './ConcertDetailsForSearchWrapper.css'
import PastConcertDetailsForSearch from "./PastConcertDetailsForSearch";

const PastConcertDetailsForSearchWrapper = ({ concerts }) => {
    const concertsPerPage = 10; // 每页显示的演出数量
    const [currentPage, setCurrentPage] = useState(1); // 当前页码
    const indexOfLastConcert = currentPage * concertsPerPage;
    const indexOfFirstConcert = indexOfLastConcert - concertsPerPage;
    const currentConcerts = concerts.slice(indexOfFirstConcert, indexOfLastConcert);
    const totalPages = Math.ceil(concerts.length / concertsPerPage);
    const handlePageChange = (page) => setCurrentPage(page);

    const Pagination = () => (
        <div className="pagination">
            {Array.from({ length: totalPages }).map((_, index) => (
                <button
                    key={index + 1}
                    onClick={() => handlePageChange(index + 1)}
                    className={`page-button ${currentPage === index + 1 ? 'active' : ''}`}
                >
                    {index + 1}
                </button>
            ))}
        </div>
    );

    if (concerts.length === 0) {
        return (
            <div className="Concert-DetailsForSearch-Wrapper-noconcert">
                <NoConcerts />
            </div>
        );

    }

    return (
        <div className="Concert-DetailsForSearch-Wrapper">
            <div className="search-result-header-SearchResultConcert">
                <h2>过去的演出无法查看详情。</h2>
            </div>
            {currentConcerts.map((concert, index) => (
                <PastConcertDetailsForSearch key={index} concert={concert}/>
            ))}
            <Pagination/>
        </div>
    );
};

export default PastConcertDetailsForSearchWrapper;
