import React, { useState, useEffect } from 'react';
import ConcertDetailsForSearchWrapper from './ConcertDetailsForSearchWrapper';
import './BandDetailConcert.css';
import PastConcertDetailsForSearchWrapper from "./PastConcertDetailsForSearchWrapper";

const BandDetailConcert = ({ futureConcerts, artistId }) => {
    // 使用状态来追踪当前选中的选项卡
    const [activeTab, setActiveTab] = useState('future'); // 默认显示未来的演出
    const apiUrl = process.env.REACT_APP_API_URL;
    const [bandInfo, setBandInfo] = useState(null);
    const [pastConcertsData, setPastConcertsData] = useState(''); // 过去的演出数据
    // 当点击过去的演出时调用API
    useEffect(() => {
        const fetchPastConcerts = async () => {
            if (activeTab === 'past') {
                fetch(`${apiUrl}/artist/byid/${artistId}`)
                    .then((response) => response.json())
                    .then((data) => {
                        setBandInfo(data);
                        fetch(`${apiUrl}/artist/pastconcerts/${data["artistIndex"]}`)
                            .then((response) => response.json())
                            .then((concertData) => {
                                setPastConcertsData(concertData["past"]);
                            })
                            .catch((error) => console.error('Error fetching concert data: ', error));
                    })
                    .catch((error) => console.error('Error fetching band info: ', error));
            }
        };

        fetchPastConcerts();
    }, [activeTab, apiUrl, artistId]); // 依赖项列表包括activeTab, apiUrl, 和 artistId

    return (
        <div>
            {/* 选项卡切换按钮 */}
            <div className='future-pass-concert'>
                <button
                    onClick={() => setActiveTab('future')}
                    className={activeTab === 'future' ? 'active' : ''}
                >
                    未来的演出
                </button>
                <button
                    onClick={() => setActiveTab('past')}
                    className={activeTab === 'past' ? 'active' : ''}
                >
                    过去的演出
                </button>
            </div>

            {/* 根据选中的选项卡显示相应的演出列表 */}
            {activeTab === 'future' ? (
                <div>
                    <ConcertDetailsForSearchWrapper className = "ConcertDetailsForSearchWrapper01" concerts={futureConcerts}/>
                </div>
            ) : (
                <div>
                <PastConcertDetailsForSearchWrapper className = "ConcertDetailsForSearchWrapper01" concerts={pastConcertsData} />
                </div>
            )}
        </div>
    );
};

export default BandDetailConcert;
