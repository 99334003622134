import React from 'react';
import './NoConcerts.css'; // 引入 CSS 文件

const NoConcerts = () => {
    return (
        <div className="noConcerts">
            <h3>暂无演出</h3>
        </div>
    );
};

export default NoConcerts;

