import React, { useEffect, useState } from 'react';
import Header from '../component/Header';
import Footer from '../component/Footer';
import AllConcerts from '../component/AllConcerts';
import { Helmet } from 'react-helmet';
import './MoreConcert.css';

const MoreConcert = () => {
    // 使用 useState 钩子存储演出数据
    const [concerts, setConcerts] = useState([]);
    const [lastSegment, setLastSegment] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        // 获取URL的最后一部分作为城市名称
        const pathSegments = window.location.pathname.split('/');
        const lastPathSegment = decodeURIComponent(pathSegments.pop() || '');
        setLastSegment(lastPathSegment);

        // 根据城市名称获取演出信息
        fetchConcerts(lastPathSegment);
    }, []);

    const fetchConcerts = (city) => {
        fetch(`${apiUrl}/concerts/livehouse/${encodeURIComponent(city)}`)
            .then(response => response.json())
            .then(data => {
                setConcerts(data); // 存储从API获取的演出数据
            })
            .catch(error => console.error('Error fetching concerts:', error));
    };

    return (
        <div className="website">
            <Helmet>
                <title>{`BandsLib - ${lastSegment}的演出`}</title>
                <meta name="description" content={`探索${lastSegment}最新的Livehouse演出、音乐节和乐队现场。不错过任何精彩演出！`} />
            </Helmet>
            <Header />
            <div className="city-background01">
                <h2 className="city-text01">{lastSegment}</h2>
            </div>
            <AllConcerts concerts={concerts} />
            <Footer />
        </div>
    );
};

export default MoreConcert;
