import React, { useEffect, useState } from 'react';
import Header from '../component/Header';
import Footer from '../component/Footer';
import AllConcerts from '../component/AllConcerts';
import './MusicFestivalPage.css';
import {Helmet} from "react-helmet";

const MusicFestivalPage = () => {
    // 使用 useState 钩子存储演出数据
    const [festivals, setFestivals] = useState([]);
    const [lastSegment, setLastSegment] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        // 获取音乐节数据
        fetch(`${apiUrl}/concerts/subCategory/festival`)
            .then(response => response.json())
            .then(data => {
                setFestivals(data); // 存储音乐节数据
            })
            .catch(error => console.error('Error fetching festivals:', error));
    }, []);


    return (
        <div className="website">
            <Helmet>
                <title>{`BandsLib - 音乐节`}</title>
                <meta name="description" content={`探索最新的音乐节。不错过任何精彩演出！`} />
            </Helmet>
            <Header />
            <div className="city-background01">
                <h2 className="city-text01">音乐节</h2>
            </div>
            <AllConcerts concerts={festivals} />
            <Footer />
        </div>
    );
};

export default MusicFestivalPage;