import React, { useState, useEffect } from 'react';
import ConcertCardStyle2 from './ConcertCardStyle2';
import './AllConcerts.css';

const AllConcerts = ({ concerts }) => {
    const rowsPerPage = 3; // 每页显示的行数
    const concertsPerRow = 4; // 每行显示的演出数量
    const totalConcertsPerPage = rowsPerPage * concertsPerRow; // 每页总共显示的演出数量
    const [currentPage, setCurrentPage] = useState(1); // 当前页码

    // 新增状态来存储筛选条件
    const [selectedStyle, setSelectedStyle] = useState(null);

    // 使用 useEffect 钩子从演出数据中提取所有唯一的风格
    const [availableStyles, setAvailableStyles] = useState([]);

    useEffect(() => {
        const styles = new Set();
        concerts.forEach(concert => {
            concert.style.forEach(style => styles.add(style));
        });
        setAvailableStyles(Array.from(styles));
    }, [concerts]);

    // 根据当前页码过滤要显示的演出
    const startIndex = (currentPage - 1) * totalConcertsPerPage;
    const endIndex = startIndex + totalConcertsPerPage;

    // 筛选符合选定风格的演出
    const filteredConcerts = selectedStyle
        ? concerts.filter(concert => concert.style.includes(selectedStyle))
        : concerts;

    const displayedConcerts = filteredConcerts.slice(startIndex, endIndex);

    // 计算总页数
    const totalPages = Math.ceil(filteredConcerts.length / totalConcertsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
        // 滚动到页面顶部
        window.scrollTo(0, 0);
    };

    // 计算要显示的页码范围，最多显示前后 5 个页码
    const pageRange = 5;
    let startPage = Math.max(1, currentPage - pageRange);
    let endPage = Math.min(totalPages, currentPage + pageRange);

    // 确保在列表末尾时页码位置固定
    if (currentPage + pageRange > totalPages) {
        startPage = Math.max(1, totalPages - pageRange * 2);
    }

    return (
        <div className="all-concerts-wrapper"> {/* 新的包装器类名 */}
            {/* 风格选择按钮 */}
            <div className="style-buttons-container">
                <div className="style-buttons">
                    <button
                        onClick={() => setSelectedStyle(null)}
                        className={`page-style-buttons ${selectedStyle === null ? 'selected' : ''}`}
                    >
                        所有风格
                    </button>
                    {availableStyles.map(style => (
                        <button
                            key={style}
                            onClick={() => setSelectedStyle(style)}
                            className={`page-style-buttons ${selectedStyle === style ? 'selected' : ''}`}
                        >
                            {style}
                        </button>
                    ))}
                </div>
            </div>

            {/* 显示演出卡片 */}
            <div className="all-concerts-container">
                {displayedConcerts.map((concert, index) => (
                    <ConcertCardStyle2
                        key={concert.id}
                        concert={concert}
                        className="concert-card"
                    />
                ))}
            </div>
            {/* 分页 */}
            <div className="pagination">
                {startPage > 1 && (
                    <button onClick={() => handlePageChange(1)} className="page-button">1</button>
                )}
                {startPage > 2 && <span className="ellipsis">...</span>}
                {Array.from({ length: endPage - startPage + 1 }).map((_, index) => (
                    <button
                        key={startPage + index}
                        onClick={() => handlePageChange(startPage + index)}
                        className={`page-button ${currentPage === startPage + index ? 'active' : ''}`}
                    >
                        {startPage + index}
                    </button>
                ))}
                {endPage < totalPages - 1 && <span className="ellipsis">...</span>}
                {endPage < totalPages && (
                    <button onClick={() => handlePageChange(totalPages)} className="page-button">
                        {totalPages}
                    </button>
                )}
            </div>
        </div>
    );
};

export default AllConcerts;

