const provinces = {
    '直辖市': ['北京', '天津', '上海', '重庆'],
    '河北省': ['石家庄', '唐山', '秦皇岛', '邯郸'],
    '山西省': ['太原', '大同'],
    '内蒙古自治区': ['呼和浩特', '包头', '赤峰'],
    '辽宁省': ['沈阳', '大连', '鞍山', '锦州'],
    '吉林省': ['长春'],
    '黑龙江省': ['哈尔滨', '大庆'],
    '江苏省': ['南京', '苏州', '无锡', '常州', '南通', '盐城'],
    '浙江省': ['杭州', '宁波', '温州', '台州', '舟山', '嘉兴', '金华'],
    '安徽省': ['合肥'],
    '福建省': ['福州', '厦门', '泉州', '漳州'],
    '江西省': ['南昌', '九江', '赣州'],
    '山东省': ['济南', '青岛', '泰安', '潍坊', '日照', '烟台', '滨州'],
    '河南省': ['郑州', '安阳', '南阳', '洛阳', '濮阳', '三门峡'],
    '湖北省': ['武汉', '宜昌'],
    '湖南省': ['长沙'],
    '广东省': ['广州', '深圳', '珠海', '东莞', '佛山', '中山', '汕头'],
    '广西壮族自治区': ['南宁', '柳州', '桂林', '北海'],
    '海南省': ['海口'],
    '四川省': ['成都', '凉山'],
    '贵州省': ['贵阳'],
    '云南省': ['昆明', '红河', '玉溪'],
    '西藏自治区': [],
    '陕西省': ['西安'],
    '甘肃省': ['兰州'],
    '宁夏回族自治区': ['银川'],
    '新疆维吾尔自治区': ['乌鲁木齐'],
    '海外': ['海外']
};

export default provinces;