import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Flex, Text, Icon } from '@chakra-ui/react';
import { FaCalendarAlt, FaMapMarkerAlt } from 'react-icons/fa';
import './ConcertCardStyle2.css'; // 确保导入了正确的 CSS 文件

const ConcertCardStyle2 = ({ concert }) => {
    const navigate = useNavigate();
    const { concertId, imgUrl,city, artist, title, start_datetime,end_datetime, venue } = concert;

    const handleClick = () => {
        navigate(`/concert/${concertId}`, { state: { concert } });
    };
    // 格式化日期，只显示日期部分
    const startDate = new Date(start_datetime);
    const endDate = new Date(end_datetime);

    const formattedStartDate = startDate.toLocaleDateString();
    const formattedEndDate = endDate.toLocaleDateString();

    let dateDisplay;

    if (startDate.toDateString() === endDate.toDateString()) {
        // 如果开始日期和结束日期相同，则只显示结束日期
        dateDisplay = formattedEndDate;
    } else {
        // 否则显示开始日期和结束日期
        dateDisplay = `${formattedStartDate} - ${formattedEndDate}`;
    }

    // 格式化 artist 数组为字符串，或显示备用文本
    const artistNames = artist && artist.length > 0 ? artist.join(', ') : '未知艺人';

    return (
        <div className="concert-card-str2">
            <img className="concert-poster-str2" src={imgUrl} alt={`${artistNames} concert poster`} onClick={handleClick}/>
            <Box className="concert-info-str2">
                <Box className="concert-date-str2">
                    <Flex alignItems="center">
                        <Icon as={FaCalendarAlt} marginRight="2"/>
                        <Text>{dateDisplay}</Text>
                    </Flex>
                </Box>
                <Box className="concert-name-str2" onClick={handleClick}>
                    <Flex alignItems="center">
                        <Text>{title}</Text>
                    </Flex>
                </Box>
                <Box className="concert-location-str2">
                    <Flex alignItems="center">
                        <Icon as={FaMapMarkerAlt} marginRight="2"/>
                        <Text>{venue}, {city}</Text>
                    </Flex>
                </Box>
            </Box>
        </div>
    );
};

export default ConcertCardStyle2;