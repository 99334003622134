import React, { useContext, useEffect, useState } from 'react';
import { Box, Flex, Button, Input, InputGroup, InputLeftElement, Image, useMediaQuery } from '@chakra-ui/react';
import { CloseIcon, SearchIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import logo from "../assets/img/logo3.png";
import ArtistSuggestions from './ArtistSuggestions';
import { AuthContext } from "./AuthContext";
import headimg from "../assets/img/newuser.png";



const Header = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [isSearchBoxOpen, setIsSearchBoxOpen] = useState(true);
    const navigate = useNavigate();
    const [isLessThan700px] = useMediaQuery('(max-width: 700px)'); // Media query
    const [isUserMenuOpen, setIsUserMenuOpen] = useState(false); // 新状态用于控制用户菜单的显示
    const [userProfile, setUserProfile] = useState({ avatarUrl: '', nickname: '' });
    const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
    const userUrl = process.env.REACT_APP_USER_URL;
    const apiUrl = process.env.REACT_APP_API_URL;

    const fetchUserProfile = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const response = await fetch(`${userUrl}/user/myprofile`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`Error: ${response.status}`);
                }

                const profileData = await response.json();
                setUserProfile({
                    avatarUrl: profileData.avatarUrl || headimg,
                    nickname: profileData.nickname,
                });
                setIsLoggedIn(true); // Set login status to true on successful data retrieval
            } catch (error) {
                console.error('获取用户资料失败:', error);
                setIsLoggedIn(false); // Set login status to false on failure
            }
        } else {
            setIsLoggedIn(false); // Set login status to false if no token is present
        }
    };

    useEffect(() => {
        fetchUserProfile(); // Fetch user profile on component mount
    }, [isLoggedIn]);

    useEffect(() => {
        // 检查 localStorage 中是否有 token 来更新登录状态
        const token = localStorage.getItem('token');
        setIsLoggedIn(token != null);
    }, [setIsLoggedIn]);

    const UserAvatar = () => {
        return (
            isUserMenuOpen ? (
                <div onClick={() => setIsUserMenuOpen(!isUserMenuOpen)} style={{cursor: 'pointer'}}>
                    <CloseIcon style={{fontSize: '24px', color: 'black'}}/>
                </div>
            ) : (
                <Image
                    src={userProfile.avatarUrl}
                    alt="用户头像"
                    onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}
                    style={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        objectFit: 'cover',
                        cursor: 'pointer'
                    }}
                />
            )
        );
    };


    // 更新 UserMenu 组件
    const UserMenu = () => (
        <Box position="absolute" right="0" mt="45px" bg="white" boxShadow="md" zIndex="popover" w="200px">
            <h2>{userProfile.nickname}</h2>
            <Button w="100%" justifyContent="flex-start" onClick={handleProfileClick}>我的资料</Button>
            {/*<Button w="100%" justifyContent="flex-start" onClick={handleSettingsClick}>关注的乐队</Button>*/}
            <Button w="100%" justifyContent="flex-start" onClick={handleLogoutClick}>退出登录</Button>
        </Box>
    );

    // 处理用户菜单中的按钮点击
    const handleProfileClick = () => {
        navigate('/myprofile'); // 跳转到我的资料页面
    };

    const handleSettingsClick = () => {/* 跳转到设置 */}
    const handleLogoutClick = () => {
        // 清除本地存储中的 JWT
        localStorage.removeItem('token');

        // 更新应用的登录状态
        setIsLoggedIn(false);

        // 关闭用户菜单
        setIsUserMenuOpen(false);

        // 重定向到登录页面或主页
        navigate('/');
        // 刷新页面
        window.location.reload();
    };

    // 登录和注册按钮
    const LoginRegisterButtons = () => (
        <Flex>
            {/*<Button ml={4} style={{ backgroundColor: '#0099CC', color: 'white', marginTop: '10px'}} onClick={handleRegisterClick}>
                创建账户
            </Button>
            <Button ml={4} style={{ backgroundColor: 'transparent', color: '#0099CC', border: '2px solid #0099CC', marginTop: '10px' }} onClick={handleLoginClick}>
                登录
            </Button>*/}
        </Flex>
    );


    const handleLoginClick = () => {
        navigate('/login'); // 使用 navigate 跳转到登录页面
    };
    const handleRegisterClick = () => {
        navigate('/register'); // 使用 navigate 跳转到注册页面
    };

    const handleSearchBoxToggle = () => {
        setIsSearchBoxOpen(!isSearchBoxOpen); // 点击乐队选项时切换搜索框的显示状态
    };
    const handleSearch = async () => {
        try {
            // 先调用演出信息接口
            const response = await fetch(`${apiUrl}/concerts/search/${searchQuery}`);
            const concertsData = await response.json();


            // 然后使用 navigate 跳转，并传递搜索结果
            navigate('/search-result', { state: { searchResults: concertsData } });
        } catch (error) {
            console.error("Error fetching concert data:", error);
        }
    };


    const handleLogoClick = () => {
        navigate('/'); // 跳转到主页
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            // 如果按下的是回车键，则触发搜索操作
            handleSearch();
        }
    };

    const handleInputChange = async (e) => {
        const query = e.target.value;
        setSearchQuery(query);

        // 在输入框变化时，将搜索框状态设置为显示
        setIsSearchBoxOpen(true);
        // 调用API来获取艺术家提示信息
        try {
            const response = await fetch(`${apiUrl}/artist/suggestions/${query}`);
            const suggestionsData = await response.json();
            setSuggestions(suggestionsData);
        } catch (error) {
            console.error("Error fetching artist suggestions:", error);
        }
    };

    return (
        <Box
            bg="#FFFFFF"
            w="100%"
            p={4}
            color="#FFFFFF"
            borderTop="1px solid white"
            borderBottom="1px solid white"
            position="relative"
        >
            <Flex
                justify="space-between"
                align="center"
                flexDirection={isLessThan700px ? 'column' : 'row'}
            >
                <Image
                    src={logo}
                    alt="Logo"
                    maxWidth="180px"
                    mr={4}
                    onClick={handleLogoClick}
                    cursor="pointer"
                    mb={isLessThan700px ? 4 : 0}
                />

                <InputGroup
                    style={{ position: 'sticky', top: '10px', zIndex: 1000 }}
                    w={{ base: "ayto", md: "600px" }}
                    ml={isLessThan700px ? 0 : '10%'}
                >
                    <InputLeftElement
                        pointerEvents="none"
                        children={<SearchIcon color="gray.500" />}
                    />
                    <Input
                        placeholder="搜索乐队，演出，livehouse，音乐节……"
                        borderRadius="50px 0 0 50px"
                        bg="white"
                        color="gray.700"
                        borderColor="gray.200"
                        focusBorderColor="gray.300"
                        _hover={{ borderColor: "gray.300" }}
                        value={searchQuery}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                    />
                    <Button onClick={handleSearch} style={{
                        borderRadius: '0 50px 50px 0',
                        backgroundColor: '#0099CC',
                        color: 'white',
                    }}>发现</Button>
                </InputGroup>

                <Flex>
                    {isLoggedIn ? (
                        <>
                            <UserAvatar />
                            {isUserMenuOpen && <UserMenu />} {/* 显示用户菜单 */}
                        </>
                    ) : (
                        <LoginRegisterButtons />
                    )}
                </Flex>

            </Flex>

            {isSearchBoxOpen && suggestions.length > 0 && <ArtistSuggestions suggestions={suggestions} handleOptionClick={handleSearchBoxToggle} />}
        </Box>
    );
};

export default Header;
