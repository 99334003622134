import React, { useState, useEffect } from 'react';
import {useLocation, useParams} from 'react-router-dom';
import Header from '../component/Header';
import Footer from '../component/Footer';
import ShowConcertInfo from '../component/ShowConcertInfo';
import UpcomingConcerts from "../component/UpcomingConcerts";
import GetTickets from '../component/GetTickets';
import BandsContainer from "../component/BandsContainer";
import './page.css'

const ConcertDetailPage = () => {


    const [similarConcerts, setSimilarConcerts] = useState([]);
    const [nearbyConcerts, setNearbyConcerts] = useState([]);
    const [similarArtists, setSimilarArtists] = useState([]); // State for similar artists
    const location = useLocation();
    // 新增状态来存储concert详情
    const [concert, setConcert] = useState(null);
    const apiUrl = process.env.REACT_APP_API_URL;
    const userUrl = process.env.REACT_APP_USER_URL;
    const [followedArtistIds, setFollowedArtistIds] = useState([]); // 存储用户关注的艺术家 ID
    let { concertId } = useParams();

    useEffect(() => {
        const fetchFollowedArtists = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const response = await fetch(`${userUrl}/user/followedArtists`, {
                        headers: { 'Authorization': `Bearer ${token}` }
                    });
                    if (response.ok) {
                        const data = await response.json();
                        setFollowedArtistIds(data);
                    }
                } catch (error) {
                    console.error('Error fetching followed artists:', error);
                }
            }
        };

        fetchFollowedArtists();
    }, [userUrl]);
    const handleFollowChange = async (artistId, shouldFollow) => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const response = await fetch(`${userUrl}/user/${shouldFollow ? 'followBand' : 'unfollowBand'}`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ artistId })
                });

                if (response.ok) {
                    // 更新前端状态，以反映新的关注状态
                    if (shouldFollow) {
                        setFollowedArtistIds(prevIds => [...prevIds, artistId]);
                    } else {
                        setFollowedArtistIds(prevIds => prevIds.filter(id => id !== artistId));
                    }
                } else {
                    // 处理错误情况
                    alert('操作失败，请稍后重试。');
                    console.error('Error during follow/unfollow action:', response.statusText);
                }
            } catch (error) {
                // 处理网络错误或其他异常
                alert('发生错误，请检查网络连接并重试。');
                console.error('Error during follow/unfollow action:', error);
            }
        } else {
            // 如果没有 token，提示用户登录
            alert('请先登录以进行此操作。');
        }
    };

    const renderGetTickets = () => {
        const { xiudongLink, damailink } = concert;

        // 检查 xiudongLink 和 damailink 是否是数组
        if (Array.isArray(xiudongLink) && Array.isArray(damailink)) {
            // 合并两个数组，并创建按钮文本数组
            const combinedLinks = [...xiudongLink, ...damailink];
            const buttonTexts = combinedLinks.map(link => {
                if (xiudongLink.includes(link)) {
                    return '跳转到秀动';
                } else if (damailink.includes(link)) {
                    return '跳转到大麦';
                }
                return '获取门票';
            });

            // 如果合并后的数组为空，则不渲染 GetTickets 组件
            if (combinedLinks.length === 0) {
                return null;
            }

            return (
                <GetTickets
                    buttonTexts={buttonTexts}
                    ticketLinks={combinedLinks}
                />
            );
        }
        return null;
    };

    useEffect(() => {
        // 根据id从后端获取concert详情
        fetch(`${apiUrl}/concerts/byConcertId/${concertId}`)
            .then(response => response.json())
            .then(data => {
                setConcert(data[0]); // 假设数据是数组形式
            })
            .catch(error => console.error('Error fetching concert details:', error));
    }, [apiUrl, concertId]); // 依赖项只包括 apiUrl 和 id

    useEffect(() => {
        // 确保 concert 数据已加载
        if (!concert) return;

        const style = Array.isArray(concert.style) && concert.style.length > 0 ? concert.style[0] : "摇滚";

        // Fetch similar concerts
        fetch(`${apiUrl}/concerts/style/${style}`)
            .then(response => response.json())
            .then(data => setSimilarConcerts(data))
            .catch(error => console.error('Error fetching similar concerts:', error));

        // 注意：这里加了一个额外的检查
        if (!concert.city) return;

        fetch(`${apiUrl}/concerts/livehouse/${concert.city}`)
            .then(response => response.json())
            .then(data => setNearbyConcerts(data))
            .catch(error => console.error('Error fetching similar concerts:', error));

        // Fetch similar artists
        fetch(`${apiUrl}/artist/genre/${style}`)
            .then(response => response.json())
            .then(data => setSimilarArtists(data))
            .catch(error => console.error('Error fetching similar artists:', error));
    }, [apiUrl, concert]); // 依赖项包括 concert



    if (!concert) {
        return <div>Loading...</div>; // 当 concert 对象不存在时显示加载信息
    }
    return (
        <div className="website">
            <Header/>
            <ShowConcertInfo concert={concert}/>
            {renderGetTickets()} {/* 在这里调用 renderGetTickets 方法 */}
            <div className="upcoming-concerts-wrapper">
                <UpcomingConcerts concerts={similarConcerts} title="您可能感兴趣的演出" category="您可能感兴趣的演出"/>
            </div>
            <div className="upcoming-concerts-wrapper">
                <UpcomingConcerts concerts={nearbyConcerts} title="邻近演出" category="邻近演出"/>
            </div>
            <div className="similar-artists-wrapper"> {/* New wrapper for similar artists */}
                <BandsContainer
                    className="bands-container"
                    bands={similarArtists}
                    text="您可能喜欢的乐队"
                    followedArtistIds={followedArtistIds}
                    onFollowChange={handleFollowChange} // 将 handleFollowChange 传递给 BandsContainer
                />
            </div>
            <Footer/>
        </div>
    );
};

export default ConcertDetailPage;