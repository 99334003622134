import React from 'react';
import { useNavigate } from 'react-router-dom';
import ConcertCard from './ConcertCard';
import { Flex, Button } from '@chakra-ui/react';
import './UpcomingConcerts.css';

const UpcomingConcerts = ({ concerts, title,title_detail, category }) => {
    const navigate = useNavigate();

    const handleViewAllClick = () => {
        navigate(`/city-concerts/${category}`, { state: { concerts } });
    };

    const displayedConcerts = concerts.slice(0, 10);

    return (
        <div>
            <Flex justifyContent="space-between" alignItems="center">
                <div>
                    <h2 className="concerts-title">{title}</h2>
                    <h2 className="concerts-detail">{title_detail}</h2>
                </div>
                <Button
                    variant="link"
                    colorScheme="black"
                    className="view-all-button"
                    onClick={handleViewAllClick}
                >
                    查看全部 →
                </Button>
            </Flex>
            <div className="concerts-cards-container">
                {displayedConcerts.map((concert) => (
                    <ConcertCard
                        key={concert.id}
                        concert={concert}
                    />
                ))}
            </div>
        </div>
    );
};

export default UpcomingConcerts;
