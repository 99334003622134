import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react'; // 导入 ChakraProvider

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ChakraProvider> {/* 使用 ChakraProvider 包裹 App */}
            <App />
        </ChakraProvider>
    </React.StrictMode>
);

// 如果你想在你的应用中开始测量性能，将一个函数传递给 reportWebVitals 来记录结果（例如：reportWebVitals(console.log)）
// 或者发送到一个分析端点。了解更多：https://bit.ly/CRA-vitals
reportWebVitals();
