import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../component/Header';
import Footer from '../component/Footer';
import './MyProfile.css';

const MyProfile = () => {
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState({});
    const [editMode, setEditMode] = useState(false);
    const userUrl = process.env.REACT_APP_USER_URL;


    const handleEdit = () => {
        setEditMode(true);
    };
    const handleSave = async () => {
        try {
            const response = await fetch(`${userUrl}/user/update`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userInfo) // 转换用户信息为 JSON 字符串
            });

            if (!response.ok) {
                throw new Error('Failed to update user info');
            }

            const updatedUserInfo = await response.json();
            setUserInfo(updatedUserInfo); // 更新本地状态以反映新的用户信息
            setEditMode(false); // 退出编辑模式
        } catch (error) {
            console.error('Error:', error);
            // 可能需要更复杂的错误处理逻辑，例如显示错误信息
        }
    };


    const fetchUserInfo = useCallback(async () => {
        try {
            const response = await fetch(`${userUrl}/user/myprofile`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 440) {
                navigate('/login');
                return;
            }

            if (!response.ok) {
                throw new Error('Failed to fetch user info');
            }

            const data = await response.json();
            setUserInfo(data);
        } catch (error) {
            console.error('Error:', error);
        }
    }, [userUrl, navigate]); // 注意添加 navigate 作为依赖项

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
        } else {
            fetchUserInfo();
        }
    }, [fetchUserInfo, navigate]); // 使用 useCallback 包裹的 fetchUserInfo

    return (
        <div className="website">
            <Header />
            <div className="profile-container">
                <h2>个人资料</h2>
                {editMode ? (
                    <div>
                        {/* 可编辑的输入字段 */}
                        <p>昵称: <input value={userInfo.nickName}
                                        onChange={(e) => setUserInfo({...userInfo, nickName: e.target.value})}/></p>
                        <p>生日: <input type="date" value={userInfo.birthday}
                                        onChange={(e) => setUserInfo({...userInfo, birthday: e.target.value})}/></p>
                        <p>性别:
                            <select
                                value={userInfo.gender}
                                onChange={(e) => setUserInfo({...userInfo, gender: e.target.value})}
                            >
                                <option value="">请选择</option>
                                <option value="男">男</option>
                                <option value="女">女</option>
                                <option value="其他">其他</option>
                                <option value="不愿透露">不愿透露</option>
                            </select>
                        </p>
                        <button onClick={handleSave}>保存</button>
                    </div>
                ) : (
                    <div>
                        {/* 显示用户信息 */}
                        <p>用户名: {userInfo.username}</p>
                        <p>昵称: {userInfo.nickName}</p>
                        <p>邮箱: {userInfo.email}</p>
                        <p>电话号码: {userInfo.phoneNumber}</p>
                        <p>生日: {userInfo.birthday}</p>
                        <p>性别: {userInfo.gender}</p>
                        <button onClick={handleEdit}>编辑</button>
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default MyProfile;


