import React, { useEffect } from 'react';
import {BrowserRouter as Router, Routes, Route, useLocation, Navigate} from 'react-router-dom';
import WelcomePage from './pages/WelcomePage';
import ConcertDetailPage from './pages/ConcertDetailPage';
import MoreConcert  from './pages/MoreConcert';
import SearchResultConcert from './pages/SearchResultConcert';
import LoginPage from './pages/LoginPage';
import MyProfile from './pagesAfterSignin/MyProfile';
import RegistrationPage from './pages/RegistrationPage'
import MusicFestivalPage from "./pages/MusicFestivalPage";
import { AuthProvider } from './component/AuthContext';


import './App.css';
import BandDetailPage from "./pages/BandDetailPage";

const ScrollToTop = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]); // 依赖于路径名的变化

    return null; // 此组件不渲染任何内容
};
const ProtectedRoute = ({ children }) => {
    const token = localStorage.getItem('token');

    if (!token) {
        // 如果没有 token，重定向到登录页面
        return <Navigate to="/login" />;
    }

    // 如果有 token，渲染传入的组件
    return children;
};


function App() {
    return (
        <AuthProvider> {/* 使用 AuthProvider 包裹整个应用 */}
        <Router>
            <ScrollToTop /> {/* 放置 ScrollToTop 组件 */}
            <Routes>
                <Route path="/" element={<WelcomePage />} />
                <Route path="/login" element={<LoginPage />} /> {/* 添加这个路由 */}
                <Route path="/register" element={<RegistrationPage />} />
                <Route path="/concert/:concertId" element={<ConcertDetailPage />} />
                <Route path="/city-concerts/:category" element={<MoreConcert />} />
                <Route path="/search-result" element={<SearchResultConcert />} />
                <Route path="/band-detail/:artistId" element={<BandDetailPage />} /> {/* 使用 artistId 作为路径参数 */}
                <Route path="/category/festival" element={<MusicFestivalPage />} /> {/* 使用 artistId 作为路径参数 */}
                {/* 使用 ProtectRoute */}
                <Route path="/myprofile" element={
                    <ProtectedRoute>
                        <MyProfile />
                    </ProtectedRoute>
                } />
            </Routes>
        </Router>
        </AuthProvider>
    );
}

export default App;
