import React from 'react';
import Header from '../component/Header';
import Footer from '../component/Footer';
import { useLocation } from 'react-router-dom';
import './SearchResultConcert.css'
import ConcertDetailsForSearchWrapper from '../component/ConcertDetailsForSearchWrapper'; // 更新路径

const SearchResultConcert = () => {
    const location = useLocation();
    const { searchResults } = location.state || { searchResults: [] };

    return (
        <div className="website-SearchResultConcert">
            <Header/>
            <div className="searchHeading">
                <h2 className="searchHeading-text">搜索</h2>
            </div>
            <div>
                {searchResults.length > 0 ? (
                    <ConcertDetailsForSearchWrapper concerts={searchResults}/>
                ) : (
                    <p>No results found.</p>
                )}
            </div>
            <Footer/>
        </div>
    );
};

export default SearchResultConcert;


