import React from 'react';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { CloseIcon } from '@chakra-ui/icons';
import './ArtistSuggestions.css'; // 确保正确引入CSS文件

const ArtistSuggestions = ({ suggestions, handleOptionClick }) => {
    const top10Suggestions = suggestions.slice(0, 5);

    const handleItemClick = () => {
        handleOptionClick();
    };

    return (
        <Box className="artist-suggestions-box">
            <CloseIcon className="close-button" onClick={handleOptionClick} /> {/* 确保这行代码被包含 */}
            {top10Suggestions.map((artist) => (
                <Box key={artist["artistId"]} className="suggestion-item">
                    <Link to={`/band-detail/${artist["artistId"]}`} onClick={handleItemClick}>
                        <Flex alignItems="center">
                            <Image src={artist["image_url"]} alt={artist.name} className="suggestion-image" />
                            <Text className="suggestion-text">{artist.name}</Text>
                        </Flex>
                    </Link>
                </Box>
            ))}
        </Box>
    );
};

export default ArtistSuggestions;