import React, { useState } from 'react';
import './otherlogin.css';
import wechatlogin from '../assets/img/wechat.png';

const OtherLogin = () => {
    const [qrUrl, setQrUrl] = useState(null); // State to store the QR code URL
    const [isLoading, setIsLoading] = useState(false); // 新增的状态，用于跟踪加载状态
    const userUrl = process.env.REACT_APP_USER_URL;

    const handleWechatLogin = async () => {
        try {
            setIsLoading(true); // 开始加载时设置为true
            const response = await fetch(`${userUrl}/auth/wxLogin`, {
                method: 'GET',
            });
            const data = await response.json();
            if (data && data.code === 0) {
                localStorage.setItem('token', data.data.token);
                setQrUrl(data.data.qrUrl); // 更新QR码URL
                setIsLoading(false); // 请求结束后设置为false
            } else {
                throw new Error('微信登录出错');
            }
        } catch (error) {
            console.error('微信登录过程中出错:', error);
            setIsLoading(false); // 出错时也要设置为false
        }
    };


    return (
        <div className="otherlogin-container">
            <p style={{ color: 'gray', textAlign: 'center', paddingTop: '20px' }}>其他登录方式</p>
            <div className="wechat-login-button" style={{ textAlign: 'center' }}>
                {!isLoading && (
                    <button onClick={handleWechatLogin}>
                        <img src={wechatlogin} alt="WeChat Login" style={{ width: '30px', height: '30px' }} />
                    </button>
                )}
                {isLoading && <div className="loading-indicator"></div>}
            </div>
            {qrUrl && (
                <div className="qr-code-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px' }}>
                    <img src={qrUrl} alt="WeChat QR Code" style={{ width: '200px', height: '200px' }} />
                </div>
            )}
        </div>
    );

};

export default OtherLogin;

