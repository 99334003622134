import React from 'react';
import './Footer.css';
import { Image, Center } from "@chakra-ui/react";
import logo from "../assets/img/logo3.png";

const Footer = () => {
    return (
        <footer className="footer">
            <Center>
                <Image
                    src={logo}
                    alt="Logo"
                    maxWidth="180px"
                />
            </Center>
            <div className="footer-content">
                <h2>联系我们: weiwen@bandslib.com</h2>
                <h2>公众号：BandsLib</h2>
                <h2>我们诚挚邀请您提出宝贵的建议、指出可能的错误，以及提出任何问题。您的反馈对我们至关重要。</h2>
                <h2>&copy; {new Date().getFullYear()} Bandslib. 保留所有权利。</h2>
            </div>
        </footer>
    );
};

export default Footer;
