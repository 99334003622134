import React, {useState, useEffect, useContext} from 'react';
import Modal from 'react-modal';
import './WelcomePage.css';
import Header from '../component/Header';
import bgimg from '../img/HeaderImg/headimg3.png';
import UpcomingConcerts from '../component/UpcomingConcerts';
import BandsContainer from '../component/BandsContainer';
import Footer from '../component/Footer';
import provinces from '../assets/provincesData'; // 确保路径正确
import { useNavigate } from 'react-router-dom';
import {Button,} from '@chakra-ui/react';
import {FaMapMarkerAlt, FaCrosshairs, FaSearch} from 'react-icons/fa';
import { AuthContext } from "../component/AuthContext";
import UpcomingFestival from "../component/UpcomingFestival"; // 确保路径正确


Modal.setAppElement('#root');



function WelcomePage () {
    const hotCities = [
        '北京', '上海', '广州', '深圳', '杭州', '南京', '重庆', '天津'
    ];

    const [concerts, setConcerts] = useState([]);
    const [festivals, setFestivals] = useState([]);
    const [bands, setBands] = useState([]);
    const [locationDetails, setLocationDetails] = useState({ city: "上海" });
    const [showModal, setShowModal] = useState(false);
    const [recommendedCities, setRecommendedCities] = useState(hotCities);
    const [followedArtistIds, setFollowedArtistIds] = useState([]); // 存储用户关注的艺术家 ID
    const navigate = useNavigate();
    const [lastLocationFetchTime, setLastLocationFetchTime] = useState(0);
    const LOCATION_FETCH_COOLDOWN = 120000; // 冷却时间为60000毫秒，即1分钟

    const apiUrl = process.env.REACT_APP_API_URL;
    const userUrl = process.env.REACT_APP_USER_URL;
    const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext); // 使用Context

    const [showCityModal, setShowCityModal] = useState(false);
    const [inputCity, setInputCity] = useState('');

    // 封装获取演出数据的逻辑
    const fetchConcerts = (city) => {
        fetch(`${apiUrl}/concerts/livehouse/${encodeURIComponent(city)}`)
            .then(response => response.json())
            .then(data => {
                setConcerts(data); // 存储演出数据
            })
            .catch(error => console.error('Error fetching concerts:', error));
    };

    const handleOpenCityModal = () => {
        setShowCityModal(true);
    };

    const handleCloseCityModal = () => {
        setShowCityModal(false);
    };

    const handleCityInputChange = (e) => {
        setInputCity(e.target.value);
        getRecommendedCities(e.target.value);
    };

    const getRecommendedCities = (input) => {
        if (!input.trim()) {
            // 输入为空时，显示热门城市列表
            setRecommendedCities(hotCities);
        } else {
            // 输入非空时，根据输入筛选城市
            const matchingCities = [];
            Object.values(provinces).forEach(cities => {
                cities.forEach(city => {
                    if (city.toLowerCase().startsWith(input.toLowerCase())) {
                        matchingCities.push(city);
                    }
                });
            });
            setRecommendedCities(matchingCities);
        }
    };
    {/*
    useEffect(() => {
        // 检查 localStorage 中是否有 token
        const token = localStorage.getItem('token');
        setIsLoggedIn(!!token); // 使用Context的方法更新登录状态
    }, [setIsLoggedIn]);

    const handleUseCurrentLocation = () => {
        getCurrentLocation(true);
    };    */}
    // 使用当前位置
    const handleUseCurrentLocationbyrequest = () => {
        const now = Date.now();
        if (now - lastLocationFetchTime < LOCATION_FETCH_COOLDOWN) {
            alert('获取位置的操作太频繁了，请稍后再试。');
            return; // 如果还在冷却时间内，直接返回不执行后续逻辑
        }
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    fetch(`${apiUrl}/getAddressByLocation?latitude=${latitude}&longitude=${longitude}`)
                        .then(response => response.json())
                        .then(data => {
                            if (data.status === 0) {
                                setLastLocationFetchTime(now);
                                const { city } = data;
                                setLocationDetails({ city }); // 更新城市状态
                                localStorage.setItem('userCity', city); // 将城市信息保存到localStorage
                                fetchConcerts(city); // 使用新的城市信息获取演出数据
                            } else {
                                alert('获取当前城市失败');
                                setLocationDetails({ city: "上海" });
                            }
                        })
                        .catch(error => {
                            console.error('Error fetching location:', error);
                            alert('获取位置信息失败');
                            setLocationDetails({ city: "上海" });
                        });
                },
                (error) => {
                    console.error("Error getting location: ", error);
                    alert('无法获取您的位置');
                    setLocationDetails({ city: "上海" });
                }
            );
        } else {
            alert("Geolocation is not supported by this browser.");
            setLocationDetails({ city: "上海" });
        }
    };
    // 使用当前位置
    const handleUseCurrentLocation = () => {
        // 首先尝试从localStorage获取位置信息
        const savedCity = localStorage.getItem('userCity');
        if (savedCity) {
            setLocationDetails({ city: savedCity });
            fetchConcerts(savedCity); // 使用保存的城市信息获取演出数据
        } else if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    fetch(`${apiUrl}/getAddressByLocation?latitude=${latitude}&longitude=${longitude}`)
                        .then(response => response.json())
                        .then(data => {
                            if (data.status === 0) {
                                const { city } = data;
                                setLocationDetails({ city }); // 更新城市状态
                                localStorage.setItem('userCity', city); // 将城市信息保存到localStorage
                                fetchConcerts(city); // 使用新的城市信息获取演出数据
                            } else {
                                alert('获取当前城市失败');
                                setLocationDetails({ city: "上海" });
                            }
                        })
                        .catch(error => {
                            console.error('Error fetching location:', error);
                            alert('获取位置信息失败');
                            setLocationDetails({ city: "上海" });
                        });
                },
                (error) => {
                    console.error("Error getting location: ", error);
                    alert('无法获取您的位置');
                    setLocationDetails({ city: "上海" });
                }
            );
        } else {
            alert("Geolocation is not supported by this browser.");
            setLocationDetails({ city: "上海" });
        }
    };


    useEffect(() => {
        // 从localStorage获取城市信息，如果不存在，则自动使用当前位置
        const savedCity = localStorage.getItem('userCity');
        if (savedCity) {
            setLocationDetails({ city: savedCity });
            fetchConcerts(savedCity); // 使用保存的城市信息获取演出数据
        } else {
            handleUseCurrentLocation(); // 没有保存的城市信息，获取当前位置
        }
    }, []);


    const handleRegisterClick = () => {
        navigate('/register'); // 使用 navigate 跳转到注册页面
    };
    // 辅助函数：检查城市是否在省份数据中
    const isCityInProvinces = (city, provincesData) => {
        return Object.values(provincesData).some(province => province.includes(city));
    };
    {/*
    const getCurrentLocation = () => {
        // 直接调用后端API以通过用户IP地址获取位置信息
        fetch(`${apiUrl}/getLocationByIp`)
            .then(response => response.json())
            .then(data => {
                // 检查返回状态
                if (data.status === 0) {
                    // 请求成功
                    let city = data.city; // 使用新的数据结构来获取城市名称
                    // 检查城市是否在支持的省份列表中
                    if (!isCityInProvinces(city, provinces)) {
                        alert(`暂不支持当前城市：${city}`);
                        city = "上海"; // 如果不支持，则默认设置为上海
                    }
                    setLocationDetails({ city });
                } else {
                    // 请求失败，可以根据需要进行处理，例如设置默认城市或显示错误信息
                    alert('获取当前城市失败:', data.status);
                    // 设置默认城市为上海，或者根据实际情况处理
                    setLocationDetails({ city: "上海" });
                }
            })
            .catch(error => console.error('Error fetching location:', error));
    };
    */}

    useEffect(() => {
        // 检查 localStorage 中是否有 token
        const token = localStorage.getItem('token');
        if (token) {
            setIsLoggedIn(true);
        }
    }, [setIsLoggedIn]);


    useEffect(() => {
        //getCurrentLocation(); 暂不启用
        // 获取演出数据


        // 获取音乐节数据
        fetch(`${apiUrl}/concerts/subCategory/festival`)
            .then(response => response.json())
            .then(data => {
                setFestivals(data); // 存储音乐节数据
            })
            .catch(error => console.error('Error fetching festivals:', error));

        // 获取乐队数据
        fetch(`${apiUrl}/artist/top-popular`)
            .then(response => response.json())
            .then(data => {
                setBands(data); // 直接存储整个乐队数据
            })
            .catch(error => console.error('Error fetching bands:', error));
    }, [apiUrl, locationDetails.city]);

    useEffect(() => {
        const fetchFollowedArtists = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const response = await fetch(`${userUrl}/user/followedArtists`, {
                        headers: { 'Authorization': `Bearer ${token}` }
                    });
                    if (response.ok) {
                        const data = await response.json();
                        setFollowedArtistIds(data);
                    }
                } catch (error) {
                    console.error('Error fetching followed artists:', error);
                }
            }
        };

        fetchFollowedArtists();
    }, [userUrl]);

    const handleFollowChange = async (artistId, shouldFollow) => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const response = await fetch(`${userUrl}/user/${shouldFollow ? 'followBand' : 'unfollowBand'}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ artistId })
                });

                if (response.ok) {
                    // 更新前端状态，以反映新的关注状态
                    if (shouldFollow) {
                        setFollowedArtistIds(prevIds => [...prevIds, artistId]);
                    } else {
                        setFollowedArtistIds(prevIds => prevIds.filter(id => id !== artistId));
                    }
                } else {
                    // 处理错误情况
                    alert('操作失败，请稍后重试。');
                    console.error('Error during follow/unfollow action:', response.statusText);
                }
            } catch (error) {
                // 处理网络错误或其他异常
                alert('发生错误，请检查网络连接并重试。');
                console.error('Error during follow/unfollow action:', error);
            }
        } else {
            // 如果没有 token，提示用户登录
            alert('请先登录以进行此操作。');
        }
    };


    const handleCitySelection = (city) => {
        setLocationDetails({ city }); // 更新城市状态
        localStorage.setItem('userCity', city); // 将城市信息保存到localStorage
        fetchConcerts(city); // 使用新的城市信息获取演出数据
        handleCloseCityModal();
    };

    return (
        <div className="website">
            <Header/>
            <div className="welcome-background-container">
                <img src={bgimg} alt="Poster" className="welcome-background"/>
                {/* 添加文字 */}
                <div className="welcome-text">
                    <h2 className="text-large">让每个舞台，都有你的足迹</h2>
                    <h2 className="text-small">Every Stage, Your Journey.</h2>
                    <h2 className="text-small">欢迎关注我们的公众号：Bandslib</h2>
                    {/*<h2 className="text-small">注册功能已经上线！</h2>
                    {!isLoggedIn && (
                        <button className="discover-more-button" onClick={handleRegisterClick}>
                            加入我们
                        </button>
                    )}*/}
                </div>
                <div className="bottom-right-text">
                    <h2>左侧图片来源：网络 | 右侧图片版权：弄正-Yang帆</h2>
                </div>
            </div>


            <div className="upcoming-concerts-wrapper">
                <Button
                    leftIcon={<FaMapMarkerAlt/>}
                    onClick={handleOpenCityModal}
                    bg="white" // 设置背景色为白色
                    color="black" // 可以设置文字颜色
                    borderRadius="99px"
                    // 可以添加更多样式，如边框、阴影等
                    className="outline-city-button"
                >
                    {locationDetails.city}
                </Button>
                {/* Add this Modal for city input */}
                <Modal
                    isOpen={showCityModal}
                    onRequestClose={handleCloseCityModal}
                    className="custom-modal"
                >
                    <div className="modal-close-button" onClick={handleCloseCityModal}>
                        &times; {/* 这是一个简单的 X 字符，用于表示关闭 */}
                    </div>
                    <div className="city-input-container">
                        <h2>更改城市</h2>
                        <FaSearch className="search-icon"/> {/* 放大镜图标 */}
                        <input
                            type="text"
                            value={inputCity}
                            onChange={handleCityInputChange}
                            placeholder="输入城市名称"
                            className="city-input" // 新增类名
                        />
                        <ul>
                            {recommendedCities.map(city => (
                                <button key={city} className="city-button" onClick={() => handleCitySelection(city)}>
                                    {city}
                                </button>
                            ))}
                        </ul>
                    </div>
                </Modal>


                <Button
                    leftIcon={<FaCrosshairs/>}
                    onClick={handleUseCurrentLocationbyrequest}
                    bg="white" // 设置背景色为白色
                    color="black" // 可以设置文字颜色
                    className="outline-city-button"
                    w="150px"
                    borderRadius="99px"
                >
                    使用当前位置
                </Button>
                {concerts.length > 0 ? (
                    <UpcomingConcerts concerts={concerts} title={`探索${locationDetails.city}附近的演出`}category={locationDetails.city} />
                ) : (
                    <h2 className="no-concerts-message">本地暂无演出 请切换到其他城市</h2>
                )}
                <UpcomingFestival className="Upcoming-Concerts" concerts={festivals} title="即将到来的音乐节"
                                  category="音乐节"
                                  title_detail="Upcoming Music Festivals"/>
            </div>
            <div className="Discover-more-artist">
                <div className="discover-content">
                    <h2>扩展您的音乐世界</h2>
                    {/*<button className="discover-more-button">探索更多艺术家</button>      */}
                </div>
            </div>
            <BandsContainer
                className="bands-container"
                bands={bands}
                text="当红乐队速递"
                title_detail="Popular Bands Dispatch"
                followedArtistIds={followedArtistIds}
                onFollowChange={handleFollowChange} // 将 handleFollowChange 传递给 BandsContainer
            />
            <Footer/>
        </div>
    );
}

export default WelcomePage;