import React, {useContext, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom'; // 导入 useNavigate
import Footer from "../component/Footer";
import Header from "../component/Header";
import Otherlogin from "../component/otherlogin";
import './LoginPage.css';
import {AuthContext} from "../component/AuthContext";

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const { setIsLoggedIn } = useContext(AuthContext); // 使用 useContext 钩子获取 setIsLoggedIn
    const userUrl = process.env.REACT_APP_USER_URL;

    const navigate = useNavigate(); // 创建 navigate 函数实例
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch(`${userUrl}/user/login`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });
            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }

            const data = await response.json();
            console.log('登录成功:', data);

            // 存储 JWT 到 localStorage
            localStorage.setItem('token', data.jwt);

            // 登录成功后，重定向到主页
            console.log('登录成功，即将跳转到首页');
            navigate('/');
            console.log('跳转命令已执行');

        } catch (error) {
            console.error('登录失败:', error);
            setError('登录失败，请检查您的用户名和密码。');
        }
    };

    useEffect(() => {
        const interval = setInterval(async () => {
            const jwtToken = localStorage.getItem('token'); // 从 localStorage 获取 jwt 令牌
            if (jwtToken) {
                try {
                    const jwtToken = localStorage.getItem('token'); // 从 localStorage 获取 jwt 令牌
                    const response = await fetch(`${userUrl}/auth/checkloginstatus`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${jwtToken}` // 使用 jwt 令牌
                        },
                    });
                    const data = await response.json();
                    if (data && data.loginSuccess) {
                        localStorage.setItem('token', data.token); // 更新 token
                        const jwtToken = localStorage.getItem('token'); // 从 localStorage 获取 jwt 令牌
                        console.log('JWT Token:', jwtToken); // 打印 jwt 令牌
                        alert('登录成功！正在跳转到会主界面...');
                        setIsLoggedIn(true); // 更新登录状态
                        clearInterval(interval); // 停止定时器
                        navigate('/'); // 重定向到首页

                    }
                } catch (error) {
                    console.error('检查登录状态失败:', error);
                }
            }
        }, 3000); // 每3秒执行一次

        return () => clearInterval(interval); // 组件卸载时清除定时器
    }, []); // 空依赖数组表示这个 effect 只在组件挂载时运行一次

    return (
        <div className="website">
            <Header/>
            <div className="login-container"> {/* 添加这个容器 */}
                <h2>登录</h2>
                <form onSubmit={handleSubmit} className="login-form">
                    <div className="form-group">
                        <label>
                            用户名:
                            <input
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </label>
                    </div>
                    <div className="form-group">
                        <label>
                            密码:
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </label>
                    </div>
                    <button className="login-sumbimt-button" type="submit">登录</button>
                </form>
                {error && <div className="error">{error}</div>}
                <Otherlogin/>
            </div>
            <Footer/>
        </div>
    );
};

export default LoginPage;
