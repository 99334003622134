import React from 'react';
import './ShowConcertInfo.css';
import { FaRegCalendarAlt, FaMapMarkerAlt } from 'react-icons/fa';

const ShowConcertInfo = ({ concert }) => {
    // 确保属性名与您的数据结构一致
    const { imgUrl, artist, title, start_datetime, venue, city } = concert;
    const artistNames = artist ? artist.join(', ') : null;
    const formatDate = (datetime) => {
        const date = new Date(datetime);
        // 指定时区为 'Asia/Shanghai' 以获取北京时间
        return date.toLocaleDateString('en-US', { timeZone: 'Asia/Shanghai' });
    };
    return (
        <div className="concert-info-container">
            <img className="blurred-poster" src={imgUrl} alt="Blurred Concert Poster" />
            <div className="poster-overlay"></div>

            <div className="clear-poster-container-concert">
                <img className="clear-poster-concert" src={imgUrl} alt="Concert Poster" />
            </div>

            <div className="concert-details-container">
                <div className="concert-title-container">
                    <h2>{title}</h2> {/* 更新为 title */}
                </div>
                <div className="band-name-container">
                    <h2>{artistNames}</h2> {/* 更新为 artist */}
                </div>
                <div className="date-container">
                    <div className="icon-container"><FaRegCalendarAlt /></div>
                    <div className="text-container">{formatDate(start_datetime)}</div>
                </div>
                <div className="location-container">
                    <div className="icon-container"><FaMapMarkerAlt /></div>
                    <div className="text-container">{venue}, {city}</div> {/* 更新为 city */}
                </div>
            </div>
        </div>
    );
};

export default ShowConcertInfo;

