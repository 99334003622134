// BandCard.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Image, Button, Flex } from '@chakra-ui/react';
import './BandCard.css';

const BandCard = ({ band, isFollowed, onFollowChange  }) => {
    const { name, image_url, artistId} = band; // 假设 bandId 是乐队的唯一标识符
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/band-detail/${artistId}`, { state: { band } });
    };
    const handleFollowClick = () => {
        onFollowChange(artistId, !isFollowed); // 传递 artistId 和当前关注的相反状态
    };
    return (
        <Box className="band-card-container">
            <Flex alignItems="center" justifyContent="flex-start">
                <Image
                    className="band-avatar"
                    src={image_url}
                    alt={`Avatar of ${name}`}
                    onClick={handleClick}
                />
                <Box>
                    <h2 className="band-name" onClick={handleClick} >{name}</h2>
                    <Button
                        className="follow-button"
                        onClick={handleFollowClick}
                        style={{ backgroundColor: isFollowed ? '#AAA' : '#FF6666', color: 'white' }}
                        borderRadius="full"
                    >
                        {isFollowed ? '已关注' : '关注'}
                    </Button>
                </Box>
            </Flex>
        </Box>
    );
};

export default BandCard;
