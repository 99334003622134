import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../component/Header';
import Footer from '../component/Footer';
import BandInfo from '../component/BandInfo';
import './BandDetailPage.css'
import BandDetailConcert from '../component/BandDetailConcert'; // 确保路径正确

const BandDetailPage = () => {
    const currentUrl = window.location.href;
    const urlParams = new URL(currentUrl);
    const artistId = urlParams.pathname.split('/').pop();

    const [bandInfo, setBandInfo] = useState(null);
    const [futureConcerts, setFutureConcerts] = useState([]);
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        fetch(`${apiUrl}/artist/byid/${artistId}`)
            .then((response) => response.json())
            .then((data) => {
                setBandInfo(data);
                fetch(`${apiUrl}/artist/concerts/${data["artistIndex"]}`)
                    .then((response) => response.json())
                    .then((concertData) => {
                        setFutureConcerts(concertData["future"]);
                    })
                    .catch((error) => console.error('Error fetching concert data: ', error));
            })
            .catch((error) => console.error('Error fetching band info: ', error));
    }, [apiUrl, artistId]);

    return (
        <div className="website">
            <Helmet>
                <title>{bandInfo ? `${bandInfo.name}  - 详情页 - BandsLib` : '乐队详情 |BandsLib'}</title>
                <meta name="description" content={bandInfo ? `探索${bandInfo.name}的详细信息、未来和过去的演出。` : '乐队详细信息加载中...'} />
            </Helmet>
            <Header />
            {bandInfo ? (
                <BandInfo bandInfo={bandInfo} />
            ) : (
                <p>加载数据中...</p>
            )}
            <BandDetailConcert futureConcerts={futureConcerts} artistId={artistId}/>
            <Footer />
        </div>
    );
};

export default BandDetailPage;
