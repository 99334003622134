import React, {useState, useContext, useEffect} from 'react';
import './RegistrationPage.css'
import Header from "../component/Header";
import Footer from "../component/Footer";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../component/AuthContext'; // 导入 AuthContext
import Otherlogin from "../component/otherlogin";

const RegistrationPage = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const [isUsernameValid, setIsUsernameValid] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(false);
    const [usernameMessage, setUsernameMessage] = useState('');
    const [passwordMessage, setPasswordMessage] = useState('');
    const [confirmPasswordMessage, setConfirmPasswordMessage] = useState('');

    const navigate = useNavigate(); // 创建 navigate 函数实例
    const userUrl = process.env.REACT_APP_USER_URL;
    const { setIsLoggedIn } = useContext(AuthContext); // 使用 useContext 钩子获取 setIsLoggedIn

    useEffect(() => {
        const interval = setInterval(async () => {
            const jwtToken = localStorage.getItem('token'); // 从 localStorage 获取 jwt 令牌
            if (jwtToken) {
                try {
                    const jwtToken = localStorage.getItem('token'); // 从 localStorage 获取 jwt 令牌
                    const response = await fetch(`${userUrl}/auth/checkloginstatus`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${jwtToken}` // 使用 jwt 令牌
                        },
                    });
                    const data = await response.json();
                    if (data && data.loginSuccess) {
                        localStorage.setItem('token', data.token); // 更新 token
                        const jwtToken = localStorage.getItem('token'); // 从 localStorage 获取 jwt 令牌
                        console.log('JWT Token:', jwtToken); // 打印 jwt 令牌
                        alert('登录成功！正在跳转到会主界面...');
                        setIsLoggedIn(true); // 更新登录状态
                        clearInterval(interval); // 停止定时器
                        navigate('/'); // 重定向到首页

                    }
                } catch (error) {
                    console.error('检查登录状态失败:', error);
                }
            }
        }, 3000); // 每3秒执行一次

        return () => clearInterval(interval); // 组件卸载时清除定时器
    }, []); // 空依赖数组表示这个 effect 只在组件挂载时运行一次


    // Validation logic
    const validateUsername = (username) => {
        if (!username) {
            setUsernameMessage('');
            setIsUsernameValid(false);
            return;
        }
        if (username.length < 5 || username.length > 20) {
            setUsernameMessage('用户名长度应为5-20个字符');
            setIsUsernameValid(false);
        } else if (!/^[a-zA-Z0-9_-—]+$/.test(username)) {
            setUsernameMessage('只能包含字母、数字、-、_、—');
            setIsUsernameValid(false);
        } else if (/\s/.test(username)) {
            setUsernameMessage('用户名中不能包含空格');
            setIsUsernameValid(false);
        } else {
            setUsernameMessage('用户名有效 ✔️');
            setIsUsernameValid(true);
        }
    };

    const validatePassword = (password) => {
        if (!password) {
            setPasswordMessage('');
            setIsPasswordValid(false);
            return;
        }
        if (password.length < 6 || password.length > 20) {
            setPasswordMessage('密码长度应为6-20个字符');
            setIsPasswordValid(false);
        } else if (!/^[A-Za-z\d!@#$%^&*()_\-+=\[\]{}|:;<>,.?/]+$/.test(password)) {
            setPasswordMessage('密码只能包含字母、数字和符号');
            setIsPasswordValid(false);
        } else if (["123456", "password", "000000"].includes(password)) { // Add more common passwords as needed
            setPasswordMessage('请避免使用常见弱密码');
            setIsPasswordValid(false);
        } else {
            setPasswordMessage('密码有效 ✔️');
            setIsPasswordValid(true);
        }
    };

    const validateConfirmPassword = (confirmPassword) => {
        if (!confirmPassword) {
            setConfirmPasswordMessage('');
            setIsConfirmPasswordValid(false);
            return;
        }
        if (confirmPassword !== password) {
            setConfirmPasswordMessage('两次输入的密码不一致');
            setIsConfirmPasswordValid(false);
        } else {
            setConfirmPasswordMessage('密码匹配 ✔️');
            setIsConfirmPasswordValid(true);
        }
    };


    //处理注册按钮逻辑
    const handleSubmit = async (event) => {
        event.preventDefault();
        const userData = {
            username: username,
            password: password,
            email: email,
            phoneNumber: "", // 假设这是从表单中获取的
            accountStatus: "active", // 可能需要从某处获取或设置为默认值
            role: "user", // 同上
            birthday: "", // 假设这是从表单中获取的
            gender: "" // 假设这是从表单中获取的
        };

        try {
            const response = await fetch(`${userUrl}/user/register`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData)
            });

            if (response.status === 200) {
                const data = await response.json();
                localStorage.setItem('token', data.jwt); // 保存令牌
                setIsLoggedIn(true); // 更新登录状态为已登录
                alert('注册成功！正在跳转到会主界面...');
                navigate('/'); // 重定向到首页
            } else {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.error("Error during registration:", error);
        }
    };



    return (
        <div className="website">
            <Header/>
            <div className="registration-container">
                <h2>注册账户</h2>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label>
                            <h2>用户名:</h2>
                            <input
                                type="text"
                                value={username}
                                onChange={(e) => {
                                    setUsername(e.target.value);
                                    validateUsername(e.target.value);
                                }}
                            />
                            <div className="message-container" style={{color: isUsernameValid ? 'green' : 'red'}}>
                                {usernameMessage}
                            </div>
                        </label>
                    </div>
                    <div>
                        <label>
                            <h2>密码:</h2>
                            <input
                                type={showPassword ? "text" : "password"}
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    validatePassword(e.target.value);
                                }}
                            />
                            <div className="message-container" style={{color: isPasswordValid ? 'green' : 'red'}}>
                                {passwordMessage}
                            </div>
                        </label>
                    </div>
                    <div>
                        <label>
                            <h2>再次输入密码:</h2>
                            <input
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => {
                                    setConfirmPassword(e.target.value);
                                    validateConfirmPassword(e.target.value);
                                }}
                            />
                            <div className="message-container"
                                 style={{color: isConfirmPasswordValid ? 'green' : 'red'}}>
                                {confirmPasswordMessage}
                            </div>
                        </label>
                    </div>
                    <button type="registerbutton"
                            disabled={!(isUsernameValid && isPasswordValid && isConfirmPasswordValid)}>
                        注册
                    </button>
                </form>

                <Otherlogin/>

            </div>
            <Footer/>
        </div>
    );
};

export default RegistrationPage;
