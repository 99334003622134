import React from 'react';
import { Flex, Button } from '@chakra-ui/react';
import './GetTickets.css'; // 确保路径正确
import damaiImage from '../assets/img/damai.png';
import xiudongImage from '../assets/img/xiudong.jpeg';

const GetTickets = ({ buttonTexts, ticketLinks }) => {

    const getBackgroundImage = (buttonText) => {
        switch (buttonText) {
            case '跳转到大麦':
                return damaiImage;
            case '跳转到秀动':
                return xiudongImage;
            default:
                return null;
        }
    };
    console.log('buttonTexts:', buttonTexts); // 打印 buttonTexts

    const buttonStyle = {
        width: '160px',
        height: '60px',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        border: '2px solid #a8a8a8',
        // border: '2px solid #a8a8a8',
        backgroundColor: 'white',
        borderRadius: '50px',
    };
    const textStyle = {
        fontSize: '36px', // 例如，将字体大小设置为 24px
        // 你可以在这里添加更多样式，如颜色、边距等
    };
    return (
        <Flex className="get-tickets-container" justify="center">
            <div className="text-and-buttons-container">
                <h3 style={textStyle} className="text-container01">跳转购票</h3>
                {buttonTexts.map((buttonText, index) => {
                    const ticketLink = ticketLinks[index];
                    return ticketLink ? (

                        <Button
                            key={`${buttonText}-${index}`}
                            onClick={() => window.open(ticketLink, "_blank")}
                            className="button-custom1"
                            style={{
                                ...buttonStyle,
                                backgroundImage: `url(${getBackgroundImage(buttonText)})`
                            }}
                        >
                        </Button>
                    ) : null;
                })}
            </div>
        </Flex>
    );
};

export default GetTickets;


